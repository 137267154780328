import { Step } from '@/shared/configs/project-creation.config'
import i18n from '@/i18n'

export const Headers: any = {
  [Step.project]: {
    title: i18n.t('Great, tell us about your project'),
    description: i18n.t(
      "Here You will be guided through a quick & simple process to set up essencial project  details. Then we'll make it easy for you to find a talent with just the right skill set, so you can  collaborate privately with them on your project."
    ),
  },
  [Step.style]: {
    title: i18n.t('Great, tell us about your project'),
    description: i18n.t(
      "Here You will be guided through a quick & simple process to set up essencial project  details. Then we'll make it easy for you to find a talent with just the right skill set, so you can  collaborate privately with them on your project."
    ),
  },
  [Step.information]: {
    title: i18n.t('Great, tell us about your project'),
    description: i18n.t(
      "Here You will be guided through a quick & simple process to set up essencial project  details. Then we'll make it easy for you to find a talent with just the right skill set, so you can  collaborate privately with them on your project."
    ),
  },
  [Step.budgetAndTimeline]: {
    title: i18n.t('Great, tell us about your project'),
    description: i18n.t(
      "Here You will be guided through a quick & simple process to set up essencial project  details. Then we'll make it easy for you to find a talent with just the right skill set, so you can  collaborate privately with them on your project."
    ),
  },
  [Step.plan]: {
    title: i18n.t('Great, tell us about your project'),
    description: i18n.t(
      "Here You will be guided through a quick & simple process to set up essencial project  details. Then we'll make it easy for you to find a talent with just the right skill set, so you can  collaborate privately with them on your project."
    ),
  },
  [Step.invitations]: {
    title: i18n.t('Great, tell us about your project'),
    description: i18n.t(
      "Here You will be guided through a quick & simple process to set up essencial project  details. Then we'll make it easy for you to find a talent with just the right skill set, so you can  collaborate privately with them on your project."
    ),
  },
  [Step.checkout]: {
    title: i18n.t("You're almost done with the boring part..."),
    description: i18n.t(
      'Dr. Ralf Speth, CEO of Jaguar once said "If you think good design is expensive, you should look at the cost of bad design."'
    ),
  },
}
